import React, { useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { RootState, useDispatch, useSelector } from 'reducers';
import logger from 'utils/logger';
import { ApolloClient } from '@apollo/client';
import getViewableQuery from './viewable.gql';
import { VIEWABLE_TYPES } from 'utils/constants';
import { ifCatchupIsItAvailable } from 'utils/broadcast';
import { isLiveDisabled } from 'components/Player/utils';
import { useI18n } from 'components/I18n';
import { useTheme } from 'server/theme/hooks';
import {
  ifLiveEventIsItAvailable,
  extractLiveEventTime,
} from 'utils/live-event';
import { PlayerContext } from 'components/Player';
import {
  Metadata,
  OpenGraphTypes,
  JsonLdTypes,
  Box,
  PageWrapper,
} from 'components';
import { replacePassive, replace } from 'router/actions';
import { RouterLocation } from 'router';
import { setInMyList, useAddToWatchlistMutation } from 'components/WatchlistButton/actions';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import * as uiActions from 'actions/ui';
import { addMessage } from 'actions/messages';
import { Container, InfoContainer, Banner, ContentContainer } from './Styles';
import ViewableInfo from './ViewableInfo';
import ContentTabs from './ContentTabs';
import useViewable from './useViewable';
import {
  findNextEpisode,
  getPageTitle,
  getPageDescription,
  getCanonicalData,
  getNextRecommendation,
} from './utils';
import {
  MOBILE_OS,
} from 'utils/constants';

const getOpenGraphType = (__typename: keyof typeof VIEWABLE_TYPES) => {
  switch (__typename) {
    case VIEWABLE_TYPES.Movie:
      return OpenGraphTypes.movie;
    case VIEWABLE_TYPES.Show:
      return OpenGraphTypes.show;
    case VIEWABLE_TYPES.Episode:
      return OpenGraphTypes.episode;
    case VIEWABLE_TYPES.Program:
    case VIEWABLE_TYPES.Channel:
    default:
      return OpenGraphTypes.otherVideo;
  }
};

const getJsonLdType = (__typename: keyof typeof VIEWABLE_TYPES) => {
  switch (__typename) {
    case VIEWABLE_TYPES.Movie:
      return JsonLdTypes.movie;
    case VIEWABLE_TYPES.Show:
      return JsonLdTypes.show;
    case VIEWABLE_TYPES.Program:
    case VIEWABLE_TYPES.Channel:
    case VIEWABLE_TYPES.Episode:
    default:
      return undefined;
  }
};

type WatchLocation = {
  params: {
    id: string,
    playableId?: string,
  },
  query: {
    autoplay?: boolean, // always autoplay
    info?: boolean, // always show details
    addToWatchlist?: boolean, // add to watchlist. will be removed after using
    playableId?: string, // change playable and autoplay. will be removed after using
  },
};

type WatchViewProps = {
  location: RouterLocation & WatchLocation
};

function WatchView({ location }: WatchViewProps) {
  const {
    name,
    params: { id, playableId: playableIdUrl },
    query,
  } = location;

  const options = {
    variables: {
      viewableId: id,
      broadcastId: playableIdUrl || query.playableId || '',
    },
  };

  const { data, loading, refetch } = useViewable(options);
  const viewable = data as any; // remove after refactoring all nested components

  const {
    os,
    withMiniPlayer,
    detailsTabs,
    episodeSettings,
  } = useSelector((state: RootState) => ({
    os: state.common.os,
    withMiniPlayer: !!state.settings.features.pip,
    detailsTabs: state.settings.features.detailsTabs,
    episodeSettings: state.settings.features.playNext?.episode,
  }));
  const [addToWatchlist] = useAddToWatchlistMutation({
    update(cache) {
      setInMyList(cache, viewable, true);
    },
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = useI18n();

  const context = useContext(PlayerContext) as any;
  const unmount = useRef(false);

  const playableId = playableIdUrl || viewable?.playableId;

  const isSamePlayableId = playableId === context.playableId;
  const hasEnded = isSamePlayableId && context.isPlayedToEnd;
  const canPlay = !!(!MOBILE_OS.includes(os) && viewable?.entitlement);

  const isPlayBtnVisible = useMemo(() => viewable
    && canPlay, [viewable, canPlay]);
  const isPlayAvailable = useMemo(() => isPlayBtnVisible
      && ifCatchupIsItAvailable(viewable)
      && ifLiveEventIsItAvailable(viewable)
      && !isLiveDisabled(viewable),
  [viewable]);

  const shouldAutoplay = useMemo(() => {
    const isChannel = viewable?.__typename === VIEWABLE_TYPES.Channel;
    const isLiveEvent = !!extractLiveEventTime(viewable);

    return (isChannel || isLiveEvent || query?.autoplay !== undefined)
      && query?.info === undefined;
  }, [!!viewable, id, playableIdUrl]);

  const getNextEpisode = useCallback(() => {
    if (!viewable) return null;

    const nextEpisode = findNextEpisode(viewable);
    const showNextEpisode = nextEpisode
      && nextEpisode.entitlement
      && ifCatchupIsItAvailable(nextEpisode)
      && ifLiveEventIsItAvailable(nextEpisode);

    return showNextEpisode ? {
      handler: () => {
        dispatch(replace({
          ...location,
          params: { id },
          query: { playableId: nextEpisode.defaultPlayable.id },
        }));
      },
      ...episodeSettings,
    } : null;
  }, [viewable?.seasonNumber, viewable?.episodeNumber]);

  const play = (props?: { back: boolean }) => {
    const { back = false } = props || {};
    if (!isPlayAvailable) return;

    if (context.mini && isSamePlayableId) {
      context.deactivateMiniPlayer();
      return;
    }
    const watchOffset = viewable.defaultPlayable?.watchOffset;
    const playbackStartTime = context.isPlayedToEnd ? 0 : watchOffset ?? 0;
    const nextVideo = getNextEpisode();
    const nextRecommendation = nextVideo ? null : getNextRecommendation(viewable);

    context.play({
      viewableId: viewable.id,
      broadcastId: playableIdUrl || '',
      refresh: refetch,
      viewable,
      playableId,
      playbackStartTime,
      fullPageMode: true,
      withMiniPlayer,
      nextVideo, // autoplay next episode
      nextRecommendation, // autoplay next recommendation
      back,
    })
      .catch((e: any) => {
        logger.error('Failed to start playback', e);
      });
  };

  const setHeaderTransparency = () => {
    // Set watch page to have transparent header
    dispatch(uiActions.setHeaderTransparency(name, id, true));
  };

  useEffect(() => {
    setHeaderTransparency();
    return () => {
      unmount.current = true;
    };
  }, [id]);

  if (__SERVER__) {
    // trigger dispatch on SSR step
    setHeaderTransparency();
  }

  // 1. Update player next handler if restore video
  // 2. Auto addToWatchlist from URL
  useEffect(() => {
    if (isSamePlayableId) {
      context.update({ nextVideo: getNextEpisode() });
    }

    if (viewable && 'addToWatchlist' in query) {
      dispatch(replacePassive({
        ...location,
        query: {
          ...location.query,
          addToWatchlist: undefined,
        },
      }));

      if (!viewable.inMyList) {
        void addToWatchlist({ variables: { viewableId: viewable.id } })
          .then(() => refetch());
      }
    }
  }, []);

  // Reload viewable when Episode changed
  useEffect(() => {
    if (query?.playableId) {
      if (viewable?.defaultPlayable?.id !== query.playableId) {
        void refetch();
      }
    }
  }, [query?.playableId]);

  // Autoplay when episode is changed
  useEffect(() => {
    if (query?.playableId && viewable?.defaultPlayable?.id === query.playableId) {
      play({ back: context.back });

      const { playableId: skip, ...other } = query;
      dispatch(replace({
        ...location,
        query: other,
      }));
    }
  }, [query?.playableId, viewable?.defaultPlayable?.id]);

  // 1. redirect from Episode to Show
  // 2. play video if autoplay
  // 3. fullPageMode if the same viewable and autoplay
  useEffect(() => {
    if (!viewable) {
      return;
    }

    if (viewable.__typename === VIEWABLE_TYPES.Episode && viewable.show?.id) {
      // Redirect to the Episode's Show
      dispatch(replace({
        ...location,
        params: { id: viewable.show.id, playableId },
      }));
      return;
    }

    // Note: do not autoplay Live when it becomes available (no server overload)
    if (shouldAutoplay && !isSamePlayableId) {
      play({ back: true });
    }

    // restore miniplayer if user opens same viewable and previous state is full page
    const isFullPageMode = shouldAutoplay || context.fullPageMode;
    if (isSamePlayableId && isFullPageMode) {
      context.deactivateMiniPlayer();
    }

    // close prev video Next Recommendation if info button was pressed
    if (context.nextRecommendationActive && !shouldAutoplay && context.isPlayedToEnd) {
      context.deactivateFullScreen?.();
      context.stop();
    }
  }, [!viewable]);

  // enable mini player if go to another page
  useEffect(() => () => {
    if (
      !unmount.current
      || !viewable
      || viewable.id !== context.viewableId
      || context.isPlayedToEnd
    ) {
      return;
    }

    context.deactivateFullScreen?.();

    if (
      withMiniPlayer && !context.playerError
      && !context.nextRecommendationActive
    ) {
      context.activateMiniPlayer({
        name: 'watch',
        params: {
          id: viewable.id,
          playableId: playableIdUrl,
        },
      });
    } else {
      context.stop();
      context.clearPinCodeRequirement();
    }
  }, [!viewable, context]);

  // 1. show noLive error message
  // 2. stop player if video became unavailable
  useEffect(() => {
    if (!viewable) {
      return;
    }

    if (isLiveDisabled(viewable)) {
      dispatch(addMessage({ contentId: 'detailView.noLive' }));
    }

    // stop playing channel if next program has no live
    // or video becomes unavailable
    if (!isPlayAvailable && isSamePlayableId && context.player) {
      context.stop();
    }
  }, [
    playableIdUrl,
    isPlayAvailable,
    !viewable,
  ]);

  // show player errors
  useEffect(() => {
    if (isSamePlayableId && context.playerError) {
      const { errorMessage, code } = context.playerError;
      const message = [];

      message.push(i18n.formatText(errorMessage.message, errorMessage.values));
      if (code) message.push(i18n.formatText('error.code', { code }));

      dispatch(addMessage({ contentId: message.join(' ') }));
    }
  }, [context.playerError]);

  // close mini player
  useEffect(() => {
    if (context.mini && hasEnded) {
      context.closeMiniPlayer(false);
    }
  }, [hasEnded]);

  if (!viewable && loading) {
    return null;
  }

  if (!viewable) {
    return (
      <NotFoundView />
    );
  }

  if (viewable.__typename === VIEWABLE_TYPES.Episode && viewable.show?.id) {
    // Do not open details page for an Episode
    // user will be redirected to Episode's Show (see useEffect)
    return null;
  }

  return (
    <PageWrapper>
      <Metadata
        title={getPageTitle(viewable)}
        imageUrl={viewable.metaImage}
        description={getPageDescription(viewable)}
        type={getOpenGraphType(viewable.__typename)}
        schemaType={getJsonLdType(viewable.__typename)}
        canonicalData={getCanonicalData(location, !!viewable.seasons)}
      />

      <Container>
        <Banner url={viewable.banner} />

        <InfoContainer>
          <ViewableInfo
            viewable={viewable}
            refreshData={refetch}
            onPlay={play}
            isPlayBtnVisible={isPlayBtnVisible}
            isPlayDisabled={!isPlayAvailable}
          />
        </InfoContainer>
      </Container>

      <ContentContainer>
        <Box
          mt="-2.2rem"
          width="100%"
          minHeight={`calc(100vh - ${theme.header.height} - ${theme.margin.large})`}
        >
          <ContentTabs viewable={viewable} order={detailsTabs} />
        </Box>
      </ContentContainer>
    </PageWrapper>
  );
}

export function initWatchView(
  _: any,
  apolloClient: ApolloClient<any>,
  { params }: WatchLocation,
) {
  return apolloClient.query({
    query: getViewableQuery,
    errorPolicy: 'ignore',
    variables: {
      viewableId: params.id,
      broadcastId: params.playableId || '',
    },
  });
}

export default (props: WatchViewProps) => (
  <WatchView
    key={`${props.location.params.id}/${props.location.params.playableId || ''}`}
    {...props}
  />
);
