import * as React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as campaignActions from 'actions/campaign';
import * as routerActions from 'router/actions';
import { useI18n } from 'components/I18n';
import {
  PURCHASE_FLOW_OFFER_TYPES,
} from 'utils/constants';
import {
  PaymentProviderFooter,
  AdyenResultHandler,
  SkipOnboardingButton,
} from 'components/Payment';
import {
  Box,
  Heading,
  Text,
  Metadata,
  SecondaryButton,
} from 'components';
import { useQuery } from '@apollo/client';
import NoOffersErrorPage from 'components/ErrorPage/NoOffersErrorPage';
import { Offer } from 'types';
import { RouterLocation } from 'router';
import { AppDispatch, RootState } from 'reducers';
import { flattenEdges } from 'utils/helpers';
import * as OFFERS_ENTITLEMENTS_QUERY from 'views/Checkout/offersAndEntitlements.gql';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';
import PromoCodeModal from '../PromoCodeModal';
import {
  MSG_IDS,
} from './utils';
import OfferComponent from './Offer';
import { Container } from './styles';
import logger from 'utils/logger';

type Props = {
  location: RouterLocation,
};

// Normal Offer selection component as part of SignUp (also can be opened from My Plan)
// Route: '/checkout'
function OfferSelectionView({ location }: Props) {
  const i18n = useI18n();
  const dispatch = useDispatch<AppDispatch>();

  const {
    promoCode,
    createAccount,
    redirectTo,
  } = location.query!;

  const {
    data,
    error: apolloError,
  } = useQuery(OFFERS_ENTITLEMENTS_QUERY);

  const {
    withPromoCodes,
  } = useSelector((state: RootState) => ({
    withPromoCodes: state.settings.features.payment?.allowPromoCodes || false,
  }), shallowEqual);

  const [showModal, setShowModal] = React.useState(!!promoCode);
  const [initPromoValidation, setInitPromoValidation] = React.useState(!!promoCode);

  const entitlements = flattenEdges(data?.viewer?.entitlements || []);
  const availableOffers = flattenEdges(data?.viewer?.offers || [])
    // filter out purchased offers
    .filter((offer: Offer) => !entitlements.includes(offer.id))
    // filter out unsupported offer types
    // @ts-expect-error ts-migrate(2345) FIXME
    .filter((offer: Offer) => PURCHASE_FLOW_OFFER_TYPES.includes(offer.__typename));

  const validatePromocode = async (newPromoCode: string) => {
    const expectedOfferIds = availableOffers.map((offer: Offer) => offer.id);

    try {
      const { offerIds } = await dispatch(campaignActions.getCampaign(newPromoCode));

      const matchingOfferIds = expectedOfferIds.filter((id: string) => offerIds.includes(id));

      if (!matchingOfferIds.length) {
        throw i18n.formatText('promoCode.notValidForOffer', { promoCode: newPromoCode });
      }

      dispatch(routerActions.push({
        name: 'checkout-confirmation',
        query: {
          createAccount,
          promoCode: newPromoCode,
          packageId: matchingOfferIds[0],
          redirectTo,
        },
      }));
    } catch (e) {
      setInitPromoValidation(false);
      throw i18n.formatText(
        campaignActions.extractPromocodeValidationError(JSON.stringify(e)),
        { promoCode: newPromoCode },
      );
    }
  };

  React.useEffect(() => {
    if (
      promoCode
      && withPromoCodes
      && availableOffers?.length > 1 // if 1 > auto redirect
    ) {
      void validatePromocode(promoCode as string).catch((e)=> {
        logger.warn('Promocode validation failed', e);
      });
    }
  }, [availableOffers.length]);

  const hideModal = () => {
    if (promoCode) { // remove promoCode from the url
      dispatch(routerActions.push({
        name: 'checkout',
        query: {
          createAccount,
          redirectTo,
        },
      }));
    }

    setShowModal(false);
  };

  const onOfferClick = (offerId: string) => {
    dispatch(routerActions.push({
      name: 'checkout-confirmation',
      query: {
        createAccount,
        packageId: offerId,
        redirectTo,
      },
    }));
  };

  if (apolloError) {
    return (
      <ErrorPage button="retry" icon={ERROR_ICONS.NOT_FOUND}>
        <Text id="error" />
      </ErrorPage>
    );
  }

  if (availableOffers.length === 1) {
    dispatch(routerActions.push({
      name: 'checkout-confirmation',
      query: {
        createAccount,
        promoCode,
        packageId: availableOffers[0].id,
        redirectTo,
      },
    }));
  }

  if (data && !availableOffers.length) {
    return (
      <NoOffersErrorPage hasEntitlement={!!entitlements.length} />
    );
  }

  if (!data || initPromoValidation) {
    return null;
  }

  const availableOfferTypes = PURCHASE_FLOW_OFFER_TYPES.filter(
    (offerType: string) => availableOffers
      .filter((item: Offer) => item.__typename === offerType).length > 0,
  );

  const items = availableOfferTypes.map((offerType: string) => {
    const offersOfType = availableOffers.filter((item: Offer) => item.__typename === offerType);

    return (
      <Container
        key={offerType}
        mb="xlarge"
        maxWidth="100%"
      >
        <Metadata title="metadata.checkout.title" />

        <Heading
          fontSize="sectionHeading"
          align="center"
          // @ts-expect-error ts-migrate(7053) FIXME
          id={MSG_IDS[offerType]}
        />

        <Box mt="xlarge" row wrap columnGap="xlarge">
          {offersOfType.map((offer: Offer) => (
            <OfferComponent
              key={offer.id}
              offer={offer}
              onClick={() => onOfferClick(offer.id)}
            />
          ))}
        </Box>
      </Container>
    );
  });

  return (
    <Box column mx="auto" mt="xxxlarge" maxWidth="100%">

      <Box mb="medium">
        <AdyenResultHandler />
      </Box>

      {items}

      {withPromoCodes && (
        <Box mb="large">
          <SecondaryButton
            variant="white"
            onClick={() => setShowModal(true)}
            className="e2e-promocode-cta"
          >
            <Text id="payment.promoCodeButton" />
          </SecondaryButton>

          {showModal && (
            <PromoCodeModal
              initialValue={promoCode?.toString()}
              validatePromocode={validatePromocode}
              onClose={hideModal}
            />
          )}
        </Box>
      )}

      <Box mb="xxlarge">
        <SkipOnboardingButton />
      </Box>

      <Box mb="small">
        <PaymentProviderFooter />
      </Box>
    </Box>
  );
}

export default React.memo(OfferSelectionView);
