import { Query } from './location';
import { logout } from 'actions/auth';
import * as routerActions from 'router/actions';
import { Dispatch, MiddlewareAPI } from 'redux';

export function normalizeQueryParams(query: Query | undefined | null): Query {
  const result: Query = {};

  if (!query) {
    return result;
  }

  for (const [key, value] of Object.entries(query)) {
    if (value === true || value === 'true') {
      result[key] = null;
      continue;
    }

    if (value === false || value === 'false') {
      result[key] = undefined;
      continue;
    }

    result[key] = value;
  }

  return result;
}

export function logoutUser(store: MiddlewareAPI<Dispatch, any>) {
  store.dispatch(logout(true));
  store.dispatch(routerActions.push({ name: 'log-in' })); // Redirect to login
}
